import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';

import './App.css';

import Routes from "./routes";

import useStyles from './theme'

const App: React.FC = () => {

    const classes = useStyles()

    return (

        <div className={classes.root}>

            <CssBaseline />

            <AppBar
                position="fixed"
                className={classes.appBar}
            >
 
                <Toolbar>
                    <Typography style={{width:"100%"}} variant="h6" noWrap>
                        Container Intelligence
                    </Typography>
                </Toolbar>
            </AppBar>

            <main className={classes.content}>
                <div className={classes.toolbar} />
                    <Routes />
            </main>
        </div>

  );

}

export default App;

