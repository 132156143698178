import React, { useEffect } from 'react';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
  Typography,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid'
import GridList from '@material-ui/core/GridList'
import GridListTile from '@material-ui/core/GridListTile'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'

const styles = (theme: Theme) =>
  createStyles({
    page: {
      margin: "auto",
      textAlign: "center",
    },
    heroImage: {
      backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('https://source.unsplash.com/ewiYQxLTGEU/2400x3594')",
      height: "75%",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      position: "absolute",
      top: 100,
      left: 0,
      width: "99vw"
    },
    heroText: {
      textAlign: "center",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      color: "white",
    },
    downArrow: {
      marginTop: 10,
      fontSize:50
    },
    gridContainer: {
      flexGrow: 1
    },
    overviewContainer: {
      marginTop: "10vh",
    },
    detailsContainer: {
      marginTop: "20vh",
    },
    pricingContainer: {
      marginTop: "20vh",
    },
    card: {
      margin: "auto",
      maxWidth: 345,
    },
    featureCard: {
      margin: "auto",
      maxWidth: "65vw",
      height: 150
    },
    media: {
      height: "50vh",
    },
    spanP: {
      marginTop: 10,
      marginBottom: 10,
      display:"block"
    },
    descriptionContainer : {
      margin: "auto",
      maxWidth: "65vw",
      textAlign: "center",
      paddingBottom: 40,
    },
    gridList: {
      paddingTop: 50,
      flexWrap: 'nowrap',
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: 'translateZ(0)',
    },
    title: {
      color: theme.palette.primary.light,
    },
    titleBar: {
      background:
        'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
    cardHeader: {
      backgroundColor: theme.palette.grey[200],
    },
    cardPricing: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'baseline',
      marginBottom: theme.spacing(2),
    },
    '@global': {
      ul: {
        margin: 0,
        padding: 0,
      },
      li: {
        listStyle: 'none',
      },
    },
    featureImage: {
      backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('https://source.unsplash.com/jZ2Pok_St8A/640x427')",
      height: 250,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      position: "relative"
    },
    overviewImage: {
      backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('https://source.unsplash.com/2AGaEidjId0/640x427')",
      height: 250,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      position: "relative"
    },
    pricingImage: {
      marginTop: 70,
      backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('https://source.unsplash.com/g_A3lRpp7qM/640x427')",
      height: 250,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      position: "relative"
    },
    footer: {
      marginTop: 140
    }
  });

interface Props extends WithStyles<typeof styles> {}

const Home: React.FC<Props> = props => {

  const { classes } = props

  const [featureText, setFeatureText] = React.useState("")
  const [featureIdx, setFeatureIdx] = React.useState(0)

  const supportEmailAddress = "info@containerintelligence.com"

  const heroImageUrl = "https://source.unsplash.com/ewiYQxLTGEU/1600x900"

  const descriptionImage = "https://images.unsplash.com/photo-1470843810958-2da815d0e041?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=968&q=80"

  const heroText = "The future of personal storage."

  const descriptionHeaderText = "Keep track of what's important."

  const descriptionTextP1 = "In an increasingly distributed world, Container Intelligence helps you keep track of the things that matter the most to you."

  const descriptionTextP2 = "The basics, plus a little extra to make modern life a whole lot easier."

  function handleFeatureClick(idx: number) {

    setFeatureIdx(idx)
    setFeatureText(features[idx].description)

  }

  const features = [
    {
      name: "GPS",
      icon: "gps_fixed",
      description: "Track container location in real time as it moves around the world."
    },
    {
      name: "Security",
      icon: "lock",
      description: "Best-in-class security - including strong public/private key cryptography - protects your belongings and ensures your privacy."
    },
    {
      name: "Metrics",
      icon: "timeline",
      description: "Track container metrics like weight and internal/ambient temperature."
    },
    {
      name: "Inventory",
      icon: "widgets",
      description: "Track inventory. Monitor value. Tie individual items to a container - and a location - so you never lose a thing."
    },
    {
      name: "Mobile and desktop apps",
      icon: "devices",
      description: "Native mobile and desktop applications provide a seamless user experience at home, the office or on the go."
    },
    {
      name: "Support",
      icon: "emoji_people",
      description: "Paid plans provide industry-best customer care and support for round-the-clock assistance when you need it most."
    }
  ]

  useEffect(() => {

    const interval = setInterval(() => {
      if (featureIdx === 5) {
        handleFeatureClick(0)
      } else {
        handleFeatureClick(featureIdx + 1)
      }
    }, 4000);

    return () => clearInterval(interval);

  }, [featureIdx])

  useEffect(() => {

    document.body.style.overflowX = "hidden"

    return () => { document.body.style.overflowX = "initial" }

  },[])

  return (
    
    
      <div style={{overflowX: "hidden"}}>

        <div className={classes.page}>

          <div className={classes.heroImage}>

            <div className={classes.heroText}>

              <Typography variant="h1"> The <Typography variant="h1" style={{display: "inline", textDecorationLine: "line-through"}}>future</Typography> now of personal storage. </Typography>

            </div>

          </div>

          <div id="separator" style={{height:"77vh", width: "70vw", display: "block"}}></div>
          
          <KeyboardArrowDownIcon className={classes.downArrow}/>

        </div>

        <div className={classes.overviewContainer}>

          <div style={{textAlign: "center", paddingBottom: 50}}>

            <Typography variant="h2">
              Overview
            </Typography>

          </div>

          <Grid container>

            <Grid item xs={12} style={{margin: "auto"}}>

              <div className={classes.descriptionContainer}>
                <Typography gutterBottom variant="h5" component="h2" style={{paddingBottom:20}}>
                  {descriptionHeaderText}
                </Typography>
                <Typography variant="h6" color="textSecondary" component="p">
                  <span className={classes.spanP}>
                    {descriptionTextP1}
                  </span>

                  <span className={classes.spanP}>
                    {descriptionTextP2}
                  </span>

                </Typography>
              </div>

              <div className={classes.overviewImage}></div>

            </Grid>
          </Grid>

        </div>

        <div className={classes.detailsContainer}>

          <Grid container>

            <Grid item xs={12}>

                <div style={{textAlign: "center"}}>

                  <Typography variant="h2">
                    Features
                  </Typography>

                  <Typography variant="h6" color="textSecondary" component="p" style={{width: "75vw", paddingTop: 30, margin:"auto"}}>
                    
                    <span className={classes.spanP}>
                    Container Intelligence's smart storage helps you track your things as they move with - or without - you. 

                    Watch your containers move around the globe, track inventory, and even delegate access to trusted friends 
                    or family. All with best-in-class security, of course.
                    </span>

                    <span className={classes.spanP}>
                    Container Intelligence offers cloud and native mobile solutions, so you can always access your data - 
                    and be assured it's backed up and properly maintained - but you can also use and run the entire toolset 
                    on your own - our self-hosted open source solutions are just as powerful!
                    </span>

                  </Typography>

                  <GridList className={classes.gridList} cols={features.length} cellHeight={100}>
                    {features.map((f, idx: number) => (
              
                      <GridListTile key={f.name}>
                        <IconButton
                          style={{
                            color: featureIdx === idx ? "black" : "gray"
                          }}
                          onClick={() => handleFeatureClick(idx)}
                        >
                          <Icon>{f.icon}</Icon>
                        </IconButton>
                      </GridListTile>
                    ))}
                  </GridList>

                  <div className={classes.featureImage}>

                    {featureText !== "" ? 
                        <div className={classes.heroText}>

                          <Typography 
                            variant="h6"
                            component="p"
                          >

                            {featureText}

                          </Typography>


                        </div>
                    :
                        null
                    }

                  </div>

                </div>

            </Grid>

          </Grid>

        </div>

        <div className={classes.pricingContainer}>
          <div style={{textAlign: "center", paddingBottom: 50}}>

            <Typography variant="h2" style={{paddingBottom: 30}}>
              Interested? Have questions?
            </Typography>

            <Typography variant="h6" color="textSecondary" style={{width: "75vw", margin:"auto"}}>
              We're working hard on the initial release of our smart container platform.
              If you would like to be notified when services and products are available,
              or would like additional information on our mission and future plans, 
              please send us an email at: 
              <span className={classes.spanP}>
                <a href={"mailto:"+supportEmailAddress}>{supportEmailAddress}</a>
              </span>
              
              <span className={classes.spanP}>
                We can't wait to talk to you!
              </span>
            </Typography>

        </div>
        </div>

        <div className={classes.footer}>
          <div style={{textAlign: "center", paddingBottom: 50}}>

          <div style={{width:"75%", textAlign: "center", margin: "auto", marginBottom: 30}}><hr /></div>

            <Typography variant="caption" color="textSecondary">

                      <div style={{float: "left", display:"inline", width:"45%"}}>Copyright 2020</div>
                      <div style={{float: "right", display:"inline", width:"45%"}}>Happiness Engineering</div>
                      
            </Typography>

        </div>
        </div>

      </div>
  );

}

export default withStyles(styles)(Home);

